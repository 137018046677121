
export const GENERIC_GATT_SERVICE_SHORT_ID_DESCRIPTIONS = new Map([
    //these are hex strings, without the 0x. Chrome zero extends the devices... so 0x1800 becomes 0x00001800.
    // This is a hack to make things easy.
    ['1800', "generic_access"],
    ['1801', "generic_attribute"],
    ['1802', "immediate_alert"],
    ['1803', "link_loss"],
    ['1804', "tx_power"],
    ['1805', "current_time"],
    ['1806', "reference_time_update"],
    ['1807', "next_dst_change"],
    ['1808', "glucose"],
    ['1809', "health_thermometer"],
    ['180A', "device_information"],
    ['180D', "heart_rate"],
    ['180E', "phone_alert_status"],
    ['180F', "battery_service"],
    ['1810', "blood_pressure"],
    ['1811', "alert_notification"],
    ['1812', "human_interface_device"],
    ['1813', "scan_parameters"],
    ['1814', "running_speed_and_cadence"],
    ['1815', "automation_io"],
    ['1816', "cycling_speed_and_cadence"],
    ['1818', "cycling_power"],
    ['1819', "location_and_navigation"],
    ['181A', "environmental_sensing"],
    ['181B', "body_composition"],
    ['181C', "user_data"],
    ['181D', "weight_scale"],
    ['181E', "bond_management"],
    ['181F', "continuous_glucose_monitoring"],
    ['1820', "internet_protocol_support"],
    ['1821', "indoor_positioning"],
    ['1822', "pulse_oximeter"],
    ['1823', "http_proxy"],
    ['1824', "transport_discovery"],
    ['1825', "object_transfer"],
    ['1826', "fitness_machine"],
    ['1827', "mesh_provisioning"],
    ['1828', "mesh_proxy"],
    ['1829', "reconnection_configuration"],
]);

export const ARANET4_SENSOR_SERVICE_UUID = 'f0cd1400-95da-4f4b-9ac8-aa55d312af0c'

export const DEVICE_INFORMATION_SERVICE_UUID = '0000180a-0000-1000-8000-00805f9b34fb';
export const BATTERY_SERVICE_UUID = '0000180f-0000-1000-8000-00805f9b34fb';
export const GENERIC_ACCESS_SERVICE_UUID = '00001800-0000-1000-8000-00805f9b34fb';


export const GENERIC_GATT_DEVICE_NAME_UUID = '00002a00-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_DEVICE_BATTERY_LEVEL_UUID = '00002a19-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_DEVICE_MODEL_NUMBER_STRING_UUID = '00002a24-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_SERIAL_NUMBER_STRING_UUID = '00002a25-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_HARDWARE_REVISION_STRING_UUID = '00002a27-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_SOFTWARE_REVISION_STRING_UUID = '00002a28-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_MANUFACTURER_NAME_STRING_UUID = '00002a29-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_FIRMWARE_REVISION_STRING_UUID = '00002a26-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_DEVICE_INFORMATION_SYSTEM_ID_UUID = '00002a23-0000-1000-8000-00805f9b34fb';
export const GENERIC_GATT_PREFERRED_PERIPHERAL_CONNECTION_PARAMETERS = '00002a04-0000-1000-8000-00805f9b34fb';


//https://gist.github.com/beaufortfrancois/1323816074f7383cfa574811abd6ea9c

export const GENERIC_GATT_SERVICE_UUID_DESCRIPTIONS = new Map([
    [ARANET4_SENSOR_SERVICE_UUID, "Aranet4 sensor service"],
    [GENERIC_GATT_DEVICE_NAME_UUID, 'Device Name'],
    ['00002a01-0000-1000-8000-00805f9b34fb', 'Appearance'],
    ['00002a02-0000-1000-8000-00805f9b34fb', 'Peripheral Privacy Flag'],
    ['00002a03-0000-1000-8000-00805f9b34fb', 'Reconnection Address'],
    [GENERIC_GATT_PREFERRED_PERIPHERAL_CONNECTION_PARAMETERS, 'Peripheral Preferred Connection Parameters'],
    ['00002a05-0000-1000-8000-00805f9b34fb', 'Service Changed'],
    ['00002a06-0000-1000-8000-00805f9b34fb', 'Alert Level'],
    ['00002a07-0000-1000-8000-00805f9b34fb', 'Tx Power Level'],
    ['00002a08-0000-1000-8000-00805f9b34fb', 'Date Time'],
    ['00002a09-0000-1000-8000-00805f9b34fb', 'Day of Week'],
    ['00002a0a-0000-1000-8000-00805f9b34fb', 'Day Date Time'],
    ['00002a0b-0000-1000-8000-00805f9b34fb', 'Exact Time 100'],
    ['00002a0c-0000-1000-8000-00805f9b34fb', 'Exact Time 256'],
    ['00002a0d-0000-1000-8000-00805f9b34fb', 'DST Offset'],
    ['00002a0e-0000-1000-8000-00805f9b34fb', 'Time Zone'],
    ['00002a0f-0000-1000-8000-00805f9b34fb', 'Local Time Information'],
    ['00002a10-0000-1000-8000-00805f9b34fb', 'Secondary Time Zone'],
    ['00002a11-0000-1000-8000-00805f9b34fb', 'Time with DST'],
    ['00002a12-0000-1000-8000-00805f9b34fb', 'Time Accuracy'],
    ['00002a13-0000-1000-8000-00805f9b34fb', 'Time Source'],
    ['00002a14-0000-1000-8000-00805f9b34fb', 'Reference Time Information'],
    ['00002a15-0000-1000-8000-00805f9b34fb', 'Time Broadcast'],
    ['00002a16-0000-1000-8000-00805f9b34fb', 'Time Update Control Point'],
    ['00002a17-0000-1000-8000-00805f9b34fb', 'Time Update State'],
    ['00002a18-0000-1000-8000-00805f9b34fb', 'Glucose Measurement'],
    [GENERIC_GATT_DEVICE_BATTERY_LEVEL_UUID, 'Battery Level'],
    ['00002a1a-0000-1000-8000-00805f9b34fb', 'Battery Power State'],
    ['00002a1b-0000-1000-8000-00805f9b34fb', 'Battery Level State'],
    ['00002a1c-0000-1000-8000-00805f9b34fb', 'Temperature Measurement'],
    ['00002a1d-0000-1000-8000-00805f9b34fb', 'Temperature Type'],
    ['00002a1e-0000-1000-8000-00805f9b34fb', 'Intermediate Temperature'],
    ['00002a1f-0000-1000-8000-00805f9b34fb', 'Temperature Celsius'],
    ['00002a20-0000-1000-8000-00805f9b34fb', 'Temperature Fahrenheit'],
    ['00002a21-0000-1000-8000-00805f9b34fb', 'Measurement Interval'],
    ['00002a22-0000-1000-8000-00805f9b34fb', 'Boot Keyboard Input Report'],
    [GENERIC_GATT_DEVICE_INFORMATION_SYSTEM_ID_UUID, 'System ID'],
    [GENERIC_GATT_DEVICE_MODEL_NUMBER_STRING_UUID, 'Model Number String'],
    [GENERIC_GATT_SERIAL_NUMBER_STRING_UUID, 'Serial Number String'],
    [GENERIC_GATT_FIRMWARE_REVISION_STRING_UUID, 'Firmware Revision String'],
    [GENERIC_GATT_HARDWARE_REVISION_STRING_UUID, 'Hardware Revision String'],
    [GENERIC_GATT_SOFTWARE_REVISION_STRING_UUID, 'Software Revision String'],
    [GENERIC_GATT_MANUFACTURER_NAME_STRING_UUID, 'Manufacturer Name String'],
    ['00002a2a-0000-1000-8000-00805f9b34fb', 'IEEE 11073-20601 Regulatory Certification Data List'],
    ['00002a2b-0000-1000-8000-00805f9b34fb', 'Current Time'],
    ['00002a2c-0000-1000-8000-00805f9b34fb', 'Magnetic Declination'],
    ['00002a2f-0000-1000-8000-00805f9b34fb', 'Position 2D'],
    ['00002a30-0000-1000-8000-00805f9b34fb', 'Position 3D'],
    ['00002a31-0000-1000-8000-00805f9b34fb', 'Scan Refresh'],
    ['00002a32-0000-1000-8000-00805f9b34fb', 'Boot Keyboard Output Report'],
    ['00002a33-0000-1000-8000-00805f9b34fb', 'Boot Mouse Input Report'],
    ['00002a34-0000-1000-8000-00805f9b34fb', 'Glucose Measurement Context'],
    ['00002a35-0000-1000-8000-00805f9b34fb', 'Blood Pressure Measurement'],
    ['00002a36-0000-1000-8000-00805f9b34fb', 'Intermediate Cuff Pressure'],
    ['00002a37-0000-1000-8000-00805f9b34fb', 'Heart Rate Measurement'],
    ['00002a38-0000-1000-8000-00805f9b34fb', 'Body Sensor Location'],
    ['00002a39-0000-1000-8000-00805f9b34fb', 'Heart Rate Control Point'],
    ['00002a3a-0000-1000-8000-00805f9b34fb', 'Removable'],
    ['00002a3b-0000-1000-8000-00805f9b34fb', 'Service Required'],
    ['00002a3c-0000-1000-8000-00805f9b34fb', 'Scientific Temperature Celsius'],
    ['00002a3d-0000-1000-8000-00805f9b34fb', 'String'],
    ['00002a3e-0000-1000-8000-00805f9b34fb', 'Network Availability'],
    ['00002a3f-0000-1000-8000-00805f9b34fb', 'Alert Status'],
    ['00002a40-0000-1000-8000-00805f9b34fb', 'Ringer Control point'],
    ['00002a41-0000-1000-8000-00805f9b34fb', 'Ringer Setting'],
    ['00002a42-0000-1000-8000-00805f9b34fb', 'Alert Category ID Bit Mask'],
    ['00002a43-0000-1000-8000-00805f9b34fb', 'Alert Category ID'],
    ['00002a44-0000-1000-8000-00805f9b34fb', 'Alert Notification Control Point'],
    ['00002a45-0000-1000-8000-00805f9b34fb', 'Unread Alert Status'],
    ['00002a46-0000-1000-8000-00805f9b34fb', 'New Alert'],
    ['00002a47-0000-1000-8000-00805f9b34fb', 'Supported New Alert Category'],
    ['00002a48-0000-1000-8000-00805f9b34fb', 'Supported Unread Alert Category'],
    ['00002a49-0000-1000-8000-00805f9b34fb', 'Blood Pressure Feature'],
    ['00002a4a-0000-1000-8000-00805f9b34fb', 'HID Information'],
    ['00002a4b-0000-1000-8000-00805f9b34fb', 'Report Map'],
    ['00002a4c-0000-1000-8000-00805f9b34fb', 'HID Control Point'],
    ['00002a4d-0000-1000-8000-00805f9b34fb', 'Report'],
    ['00002a4e-0000-1000-8000-00805f9b34fb', 'Protocol Mode'],
    ['00002a4f-0000-1000-8000-00805f9b34fb', 'Scan Interval Window'],
    ['00002a50-0000-1000-8000-00805f9b34fb', 'PnP ID'],
    ['00002a51-0000-1000-8000-00805f9b34fb', 'Glucose Feature'],
    ['00002a52-0000-1000-8000-00805f9b34fb', 'Record Access Control Point'],
    ['00002a53-0000-1000-8000-00805f9b34fb', 'RSC Measurement'],
    ['00002a54-0000-1000-8000-00805f9b34fb', 'RSC Feature'],
    ['00002a55-0000-1000-8000-00805f9b34fb', 'SC Control Point'],
    ['00002a56-0000-1000-8000-00805f9b34fb', 'Digital'],
    ['00002a57-0000-1000-8000-00805f9b34fb', 'Digital Output'],
    ['00002a58-0000-1000-8000-00805f9b34fb', 'Analog'],
    ['00002a59-0000-1000-8000-00805f9b34fb', 'Analog Output'],
    ['00002a5a-0000-1000-8000-00805f9b34fb', 'Aggregate'],
    ['00002a5b-0000-1000-8000-00805f9b34fb', 'CSC Measurement'],
    ['00002a5c-0000-1000-8000-00805f9b34fb', 'CSC Feature'],
    ['00002a5d-0000-1000-8000-00805f9b34fb', 'Sensor Location'],
    ['00002a5e-0000-1000-8000-00805f9b34fb', 'PLX Spot-Check Measurement'],
    ['00002a5f-0000-1000-8000-00805f9b34fb', 'PLX Continuous Measurement Characteristic'],
    ['00002a60-0000-1000-8000-00805f9b34fb', 'PLX Features'],
    ['00002a62-0000-1000-8000-00805f9b34fb', 'Pulse Oximetry Control Point'],
    ['00002a63-0000-1000-8000-00805f9b34fb', 'Cycling Power Measurement'],
    ['00002a64-0000-1000-8000-00805f9b34fb', 'Cycling Power Vector'],
    ['00002a65-0000-1000-8000-00805f9b34fb', 'Cycling Power Feature'],
    ['00002a66-0000-1000-8000-00805f9b34fb', 'Cycling Power Control Point'],
    ['00002a67-0000-1000-8000-00805f9b34fb', 'Location and Speed Characteristic'],
    ['00002a68-0000-1000-8000-00805f9b34fb', 'Navigation'],
    ['00002a69-0000-1000-8000-00805f9b34fb', 'Position Quality'],
    ['00002a6a-0000-1000-8000-00805f9b34fb', 'LN Feature'],
    ['00002a6b-0000-1000-8000-00805f9b34fb', 'LN Control Point'],
    ['00002a6c-0000-1000-8000-00805f9b34fb', 'Elevation'],
    ['00002a6d-0000-1000-8000-00805f9b34fb', 'Pressure'],
    ['00002a6e-0000-1000-8000-00805f9b34fb', 'Temperature'],
    ['00002a6f-0000-1000-8000-00805f9b34fb', 'Humidity'],
    ['00002a70-0000-1000-8000-00805f9b34fb', 'True Wind Speed'],
    ['00002a71-0000-1000-8000-00805f9b34fb', 'True Wind Direction'],
    ['00002a72-0000-1000-8000-00805f9b34fb', 'Apparent Wind Speed'],
    ['00002a73-0000-1000-8000-00805f9b34fb', 'Apparent Wind Direction'],
    ['00002a74-0000-1000-8000-00805f9b34fb', 'Gust Factor'],
    ['00002a75-0000-1000-8000-00805f9b34fb', 'Pollen Concentration'],
    ['00002a76-0000-1000-8000-00805f9b34fb', 'UV Index'],
    ['00002a77-0000-1000-8000-00805f9b34fb', 'Irradiance'],
    ['00002a78-0000-1000-8000-00805f9b34fb', 'Rainfall'],
    ['00002a79-0000-1000-8000-00805f9b34fb', 'Wind Chill'],
    ['00002a7a-0000-1000-8000-00805f9b34fb', 'Heat Index'],
    ['00002a7b-0000-1000-8000-00805f9b34fb', 'Dew Point'],
    ['00002a7d-0000-1000-8000-00805f9b34fb', 'Descriptor Value Changed'],
    ['00002a7e-0000-1000-8000-00805f9b34fb', 'Aerobic Heart Rate Lower Limit'],
    ['00002a7f-0000-1000-8000-00805f9b34fb', 'Aerobic Threshold'],
    ['00002a80-0000-1000-8000-00805f9b34fb', 'Age'],
    ['00002a81-0000-1000-8000-00805f9b34fb', 'Anaerobic Heart Rate Lower Limit'],
    ['00002a82-0000-1000-8000-00805f9b34fb', 'Anaerobic Heart Rate Upper Limit'],
    ['00002a83-0000-1000-8000-00805f9b34fb', 'Anaerobic Threshold'],
    ['00002a84-0000-1000-8000-00805f9b34fb', 'Aerobic Heart Rate Upper Limit'],
    ['00002a85-0000-1000-8000-00805f9b34fb', 'Date of Birth'],
    ['00002a86-0000-1000-8000-00805f9b34fb', 'Date of Threshold Assessment'],
    ['00002a87-0000-1000-8000-00805f9b34fb', 'Email Address'],
    ['00002a88-0000-1000-8000-00805f9b34fb', 'Fat Burn Heart Rate Lower Limit'],
    ['00002a89-0000-1000-8000-00805f9b34fb', 'Fat Burn Heart Rate Upper Limit'],
    ['00002a8a-0000-1000-8000-00805f9b34fb', 'First Name'],
    ['00002a8b-0000-1000-8000-00805f9b34fb', 'Five Zone Heart Rate Limits'],
    ['00002a8c-0000-1000-8000-00805f9b34fb', 'Gender'],
    ['00002a8d-0000-1000-8000-00805f9b34fb', 'Heart Rate Max'],
    ['00002a8e-0000-1000-8000-00805f9b34fb', 'Height'],
    ['00002a8f-0000-1000-8000-00805f9b34fb', 'Hip Circumference'],
    ['00002a90-0000-1000-8000-00805f9b34fb', 'Last Name'],
    ['00002a91-0000-1000-8000-00805f9b34fb', 'Maximum Recommended Heart Rate'],
    ['00002a92-0000-1000-8000-00805f9b34fb', 'Resting Heart Rate'],
    ['00002a93-0000-1000-8000-00805f9b34fb', 'Sport Type for Aerobic and Anaerobic Thresholds'],
    ['00002a94-0000-1000-8000-00805f9b34fb', 'Three Zone Heart Rate Limits'],
    ['00002a95-0000-1000-8000-00805f9b34fb', 'Two Zone Heart Rate Limit'],
    ['00002a96-0000-1000-8000-00805f9b34fb', 'VO2 Max'],
    ['00002a97-0000-1000-8000-00805f9b34fb', 'Waist Circumference'],
    ['00002a98-0000-1000-8000-00805f9b34fb', 'Weight'],
    ['00002a99-0000-1000-8000-00805f9b34fb', 'Database Change Increment'],
    ['00002a9a-0000-1000-8000-00805f9b34fb', 'User Index'],
    ['00002a9b-0000-1000-8000-00805f9b34fb', 'Body Composition Feature'],
    ['00002a9c-0000-1000-8000-00805f9b34fb', 'Body Composition Measurement'],
    ['00002a9d-0000-1000-8000-00805f9b34fb', 'Weight Measurement'],
    ['00002a9e-0000-1000-8000-00805f9b34fb', 'Weight Scale Feature'],
    ['00002a9f-0000-1000-8000-00805f9b34fb', 'User Control Point'],
    ['00002aa0-0000-1000-8000-00805f9b34fb', 'Magnetic Flux Density - 2D'],
    ['00002aa1-0000-1000-8000-00805f9b34fb', 'Magnetic Flux Density - 3D'],
    ['00002aa2-0000-1000-8000-00805f9b34fb', 'Language'],
    ['00002aa3-0000-1000-8000-00805f9b34fb', 'Barometric Pressure Trend'],
    ['00002aa4-0000-1000-8000-00805f9b34fb', 'Bond Management Control Point'],
    ['00002aa5-0000-1000-8000-00805f9b34fb', 'Bond Management Features'],
    ['00002aa6-0000-1000-8000-00805f9b34fb', 'Central Address Resolution'],
    ['00002aa7-0000-1000-8000-00805f9b34fb', 'CGM Measurement'],
    ['00002aa8-0000-1000-8000-00805f9b34fb', 'CGM Feature'],
    ['00002aa9-0000-1000-8000-00805f9b34fb', 'CGM Status'],
    ['00002aaa-0000-1000-8000-00805f9b34fb', 'CGM Session Start Time'],
    ['00002aab-0000-1000-8000-00805f9b34fb', 'CGM Session Run Time'],
    ['00002aac-0000-1000-8000-00805f9b34fb', 'CGM Specific Ops Control Point'],
    ['00002aad-0000-1000-8000-00805f9b34fb', 'Indoor Positioning Configuration'],
    ['00002aae-0000-1000-8000-00805f9b34fb', 'Latitude'],
    ['00002aaf-0000-1000-8000-00805f9b34fb', 'Longitude'],
    ['00002ab0-0000-1000-8000-00805f9b34fb', 'Local North Coordinate'],
    ['00002ab1-0000-1000-8000-00805f9b34fb', 'Local East Coordinate'],
    ['00002ab2-0000-1000-8000-00805f9b34fb', 'Floor Number'],
    ['00002ab3-0000-1000-8000-00805f9b34fb', 'Altitude'],
    ['00002ab4-0000-1000-8000-00805f9b34fb', 'Uncertainty'],
    ['00002ab5-0000-1000-8000-00805f9b34fb', 'Location Name'],
    ['00002ab6-0000-1000-8000-00805f9b34fb', 'URI'],
    ['00002ab7-0000-1000-8000-00805f9b34fb', 'HTTP Headers'],
    ['00002ab8-0000-1000-8000-00805f9b34fb', 'HTTP Status Code'],
    ['00002ab9-0000-1000-8000-00805f9b34fb', 'HTTP Entity Body'],
    ['00002aba-0000-1000-8000-00805f9b34fb', 'HTTP Control Point'],
    ['00002abb-0000-1000-8000-00805f9b34fb', 'HTTPS Security'],
    ['00002abc-0000-1000-8000-00805f9b34fb', 'TDS Control Point'],
    ['00002abd-0000-1000-8000-00805f9b34fb', 'OTS Feature'],
    ['00002abe-0000-1000-8000-00805f9b34fb', 'Object Name'],
    ['00002abf-0000-1000-8000-00805f9b34fb', 'Object Type'],
    ['00002ac0-0000-1000-8000-00805f9b34fb', 'Object Size'],
    ['00002ac1-0000-1000-8000-00805f9b34fb', 'Object First-Created'],
    ['00002ac2-0000-1000-8000-00805f9b34fb', 'Object Last-Modified'],
    ['00002ac3-0000-1000-8000-00805f9b34fb', 'Object ID'],
    ['00002ac4-0000-1000-8000-00805f9b34fb', 'Object Properties'],
    ['00002ac5-0000-1000-8000-00805f9b34fb', 'Object Action Control Point'],
    ['00002ac6-0000-1000-8000-00805f9b34fb', 'Object List Control Point'],
    ['00002ac7-0000-1000-8000-00805f9b34fb', 'Object List Filter'],
    ['00002ac8-0000-1000-8000-00805f9b34fb', 'Object Changed'],
    ['00002ac9-0000-1000-8000-00805f9b34fb', 'Resolvable Private Address Only'],
    ['00002acc-0000-1000-8000-00805f9b34fb', 'Fitness Machine Feature'],
    ['00002acd-0000-1000-8000-00805f9b34fb', 'Treadmill Data'],
    ['00002ace-0000-1000-8000-00805f9b34fb', 'Cross Trainer Data'],
    ['00002acf-0000-1000-8000-00805f9b34fb', 'Step Climber Data'],
    ['00002ad0-0000-1000-8000-00805f9b34fb', 'Stair Climber Data'],
    ['00002ad1-0000-1000-8000-00805f9b34fb', 'Rower Data'],
    ['00002ad2-0000-1000-8000-00805f9b34fb', 'Indoor Bike Data'],
    ['00002ad3-0000-1000-8000-00805f9b34fb', 'Training Status'],
    ['00002ad4-0000-1000-8000-00805f9b34fb', 'Supported Speed Range'],
    ['00002ad5-0000-1000-8000-00805f9b34fb', 'Supported Inclination Range'],
    ['00002ad6-0000-1000-8000-00805f9b34fb', 'Supported Resistance Level Range'],
    ['00002ad7-0000-1000-8000-00805f9b34fb', 'Supported Heart Rate Range'],
    ['00002ad8-0000-1000-8000-00805f9b34fb', 'Supported Power Range'],
    ['00002ad9-0000-1000-8000-00805f9b34fb', 'Fitness Machine Control Point'],
    ['00002ada-0000-1000-8000-00805f9b34fb', 'Fitness Machine Status'],
    ['00002aed-0000-1000-8000-00805f9b34fb', 'Date UTC'],
    ['00002b1d-0000-1000-8000-00805f9b34fb', 'RC Feature'],
    ['00002b1e-0000-1000-8000-00805f9b34fb', 'RC Settings'],
    ['00002b1f-0000-1000-8000-00805f9b34fb', 'Reconnection Configuration Control Point'],

    //from https://github.com/ghostyguo/BleUuidExplorer/blob/master/app/src/main/java/ghostysoft/bleuuidexplorer/GattAttributes.java

    ['00002700-0000-1000-8000-00805f9b34fb', 'unitless'],
    ['00002701-0000-1000-8000-00805f9b34fb', 'length (metre)'],
    ['00002702-0000-1000-8000-00805f9b34fb', 'mass (kilogram)'],
    ['00002703-0000-1000-8000-00805f9b34fb', 'time (second)'],
    ['00002704-0000-1000-8000-00805f9b34fb', 'electric current (ampere)'],
    ['00002705-0000-1000-8000-00805f9b34fb', 'thermodynamic temperature (kelvin)'],
    ['00002706-0000-1000-8000-00805f9b34fb', 'amount of substance (mole)'],
    ['00002707-0000-1000-8000-00805f9b34fb', 'luminous intensity (candela)'],
    ['00002710-0000-1000-8000-00805f9b34fb', 'area (square metres)'],
    ['00002711-0000-1000-8000-00805f9b34fb', 'volume (cubic metres)'],
    ['00002712-0000-1000-8000-00805f9b34fb', 'velocity (metres per second)'],
    ['00002713-0000-1000-8000-00805f9b34fb', 'acceleration (metres per second squared)'],
    ['00002714-0000-1000-8000-00805f9b34fb', 'wavenumber (reciprocal metre)'],
    ['00002715-0000-1000-8000-00805f9b34fb', 'density (kilogram per cubic metre)'],
    ['00002716-0000-1000-8000-00805f9b34fb', 'surface density (kilogram per square metre)'],
    ['00002717-0000-1000-8000-00805f9b34fb', 'specific volume (cubic metre per kilogram)'],
    ['00002718-0000-1000-8000-00805f9b34fb', 'current density (ampere per square metre)'],
    ['00002719-0000-1000-8000-00805f9b34fb', 'magnetic field strength (ampere per metre)'],
    ['0000271a-0000-1000-8000-00805f9b34fb', 'amount concentration (mole per cubic metre)'],
    ['0000271b-0000-1000-8000-00805f9b34fb', 'mass concentration (kilogram per cubic metre)'],
    ['0000271c-0000-1000-8000-00805f9b34fb', 'luminance (candela per square metre)'],
    ['0000271d-0000-1000-8000-00805f9b34fb', 'refractive index'],
    ['0000271e-0000-1000-8000-00805f9b34fb', 'relative permeability'],
    ['00002720-0000-1000-8000-00805f9b34fb', 'plane angle (radian)'],
    ['00002721-0000-1000-8000-00805f9b34fb', 'solid angle (steradian)'],
    ['00002722-0000-1000-8000-00805f9b34fb', 'frequency (hertz)'],
    ['00002723-0000-1000-8000-00805f9b34fb', 'force (newton)'],
    ['00002724-0000-1000-8000-00805f9b34fb', 'pressure (pascal)'],
    ['00002725-0000-1000-8000-00805f9b34fb', 'energy (joule)'],
    ['00002726-0000-1000-8000-00805f9b34fb', 'power (watt)'],
    ['00002727-0000-1000-8000-00805f9b34fb', 'electric charge (coulomb)'],
    ['00002728-0000-1000-8000-00805f9b34fb', 'electric potential difference (volt)'],
    ['00002729-0000-1000-8000-00805f9b34fb', 'capacitance (farad)'],
    ['0000272a-0000-1000-8000-00805f9b34fb', 'electric resistance (ohm)'],
    ['0000272b-0000-1000-8000-00805f9b34fb', 'electric conductance (siemens)'],
    ['0000272c-0000-1000-8000-00805f9b34fb', 'magnetic flux (weber)'],
    ['0000272d-0000-1000-8000-00805f9b34fb', 'magnetic flux density (tesla)'],
    ['0000272e-0000-1000-8000-00805f9b34fb', 'inductance (henry)'],
    ['0000272f-0000-1000-8000-00805f9b34fb', 'Celsius temperature (degree Celsius)'],
    ['00002730-0000-1000-8000-00805f9b34fb', 'luminous flux (lumen)'],
    ['00002731-0000-1000-8000-00805f9b34fb', 'illuminance (lux)'],
    ['00002732-0000-1000-8000-00805f9b34fb', 'activity referred to a radionuclide (becquerel)'],
    ['00002733-0000-1000-8000-00805f9b34fb', 'absorbed dose (gray)'],
    ['00002734-0000-1000-8000-00805f9b34fb', 'dose equivalent (sievert)'],
    ['00002735-0000-1000-8000-00805f9b34fb', 'catalytic activity (katal)'],
    ['00002740-0000-1000-8000-00805f9b34fb', 'dynamic viscosity (pascal second)'],
    ['00002741-0000-1000-8000-00805f9b34fb', 'moment of force (newton metre)'],
    ['00002742-0000-1000-8000-00805f9b34fb', 'surface tension (newton per metre)'],
    ['00002743-0000-1000-8000-00805f9b34fb', 'angular velocity (radian per second)'],
    ['00002744-0000-1000-8000-00805f9b34fb', 'angular acceleration (radian per second squared)'],
    ['00002745-0000-1000-8000-00805f9b34fb', 'heat flux density (watt per square metre)'],
    ['00002746-0000-1000-8000-00805f9b34fb', 'heat capacity (joule per kelvin)'],
    ['00002747-0000-1000-8000-00805f9b34fb', 'specific heat capacity (joule per kilogram kelvin)'],
    ['00002748-0000-1000-8000-00805f9b34fb', 'specific energy (joule per kilogram)'],
    ['00002749-0000-1000-8000-00805f9b34fb', 'thermal conductivity (watt per metre kelvin)'],
    ['0000274a-0000-1000-8000-00805f9b34fb', 'energy density (joule per cubic metre)'],
    ['0000274b-0000-1000-8000-00805f9b34fb', 'electric field strength (volt per metre)'],
    ['0000274c-0000-1000-8000-00805f9b34fb', 'electric charge density (coulomb per cubic metre)'],
    ['0000274d-0000-1000-8000-00805f9b34fb', 'surface charge density (coulomb per square metre)'],
    ['0000274e-0000-1000-8000-00805f9b34fb', 'electric flux density (coulomb per square metre)'],
    ['0000274f-0000-1000-8000-00805f9b34fb', 'permittivity (farad per metre)'],
    ['00002750-0000-1000-8000-00805f9b34fb', 'permeability (henry per metre)'],
    ['00002751-0000-1000-8000-00805f9b34fb', 'molar energy (joule per mole)'],
    ['00002752-0000-1000-8000-00805f9b34fb', 'molar entropy (joule per mole kelvin)'],
    ['00002753-0000-1000-8000-00805f9b34fb', 'exposure (coulomb per kilogram)'],
    ['00002754-0000-1000-8000-00805f9b34fb', 'absorbed dose rate (gray per second)'],
    ['00002755-0000-1000-8000-00805f9b34fb', 'radiant intensity (watt per steradian)'],
    ['00002756-0000-1000-8000-00805f9b34fb', 'radiance (watt per square metre steradian)'],
    ['00002757-0000-1000-8000-00805f9b34fb', 'catalytic activity concentration (katal per cubic metre)'],
    ['00002760-0000-1000-8000-00805f9b34fb', 'time (minute)'],
    ['00002761-0000-1000-8000-00805f9b34fb', 'time (hour)'],
    ['00002762-0000-1000-8000-00805f9b34fb', 'time (day)'],
    ['00002763-0000-1000-8000-00805f9b34fb', 'plane angle (degree)'],
    ['00002764-0000-1000-8000-00805f9b34fb', 'plane angle (minute)'],
    ['00002765-0000-1000-8000-00805f9b34fb', 'plane angle (second)'],
    ['00002766-0000-1000-8000-00805f9b34fb', 'area (hectare)'],
    ['00002767-0000-1000-8000-00805f9b34fb', 'volume (litre)'],
    ['00002768-0000-1000-8000-00805f9b34fb', 'mass (tonne)'],
    ['00002780-0000-1000-8000-00805f9b34fb', 'pressure (bar)'],
    ['00002781-0000-1000-8000-00805f9b34fb', 'pressure (millimetre of mercury)'],
    ['00002782-0000-1000-8000-00805f9b34fb', 'length (angstrom)'],
    ['00002783-0000-1000-8000-00805f9b34fb', 'length (nautical mile)'],
    ['00002784-0000-1000-8000-00805f9b34fb', 'area (barn)'],
    ['00002785-0000-1000-8000-00805f9b34fb', 'velocity (knot)'],
    ['00002786-0000-1000-8000-00805f9b34fb', 'logarithmic radio quantity (neper)'],
    ['00002787-0000-1000-8000-00805f9b34fb', 'logarithmic radio quantity (bel)'],
    ['000027a0-0000-1000-8000-00805f9b34fb', 'length (yard)'],
    ['000027a1-0000-1000-8000-00805f9b34fb', 'length (parsec)'],
    ['000027a2-0000-1000-8000-00805f9b34fb', 'length (inch)'],
    ['000027a3-0000-1000-8000-00805f9b34fb', 'length (foot)'],
    ['000027a4-0000-1000-8000-00805f9b34fb', 'length (mile)'],
    ['000027a5-0000-1000-8000-00805f9b34fb', 'pressure (pound-force per square inch)'],
    ['000027a6-0000-1000-8000-00805f9b34fb', 'velocity (kilometre per hour)'],
    ['000027a7-0000-1000-8000-00805f9b34fb', 'velocity (mile per hour)'],
    ['000027a8-0000-1000-8000-00805f9b34fb', 'angular velocity (revolution per minute)'],
    ['000027a9-0000-1000-8000-00805f9b34fb', 'energy (gram calorie)'],
    ['000027aa-0000-1000-8000-00805f9b34fb', 'energy (kilogram calorie)'],
    ['000027ab-0000-1000-8000-00805f9b34fb', 'energy (kilowatt hour)'],
    ['000027ac-0000-1000-8000-00805f9b34fb', 'thermodynamic temperature (degree Fahrenheit)'],
    ['000027ad-0000-1000-8000-00805f9b34fb', 'percentage'],
    ['000027ae-0000-1000-8000-00805f9b34fb', 'per mille'],
    ['000027af-0000-1000-8000-00805f9b34fb', 'period (beats per minute)'],
    ['000027b0-0000-1000-8000-00805f9b34fb', 'electric charge (ampere hours)'],
    ['000027b1-0000-1000-8000-00805f9b34fb', 'mass density (milligram per decilitre)'],
    ['000027b2-0000-1000-8000-00805f9b34fb', 'mass density (millimole per litre)'],
    ['000027b3-0000-1000-8000-00805f9b34fb', 'time (year)'],
    ['000027b4-0000-1000-8000-00805f9b34fb', 'time (month)'],
    ['000027b5-0000-1000-8000-00805f9b34fb', 'concentration (count per cubic metre)'],
    ['000027b6-0000-1000-8000-00805f9b34fb', 'irradiance (watt per square metre)'],
    ['000027b7-0000-1000-8000-00805f9b34fb', 'milliliter (per kilogram per minute)'],
    ['000027b8-0000-1000-8000-00805f9b34fb', 'mass (pound)'],
    ['00002800-0000-1000-8000-00805f9b34fb', 'GATT Primary Service Declaration'],
    ['00002801-0000-1000-8000-00805f9b34fb', 'GATT Secondary Service Declaration'],
    ['00002802-0000-1000-8000-00805f9b34fb', 'GATT Include Declaration'],
    ['00002803-0000-1000-8000-00805f9b34fb', 'GATT Characteristic Declaration'],
    ['00002900-0000-1000-8000-00805f9b34fb', 'Characteristic Extended Properties'],
    ['00002901-0000-1000-8000-00805f9b34fb', 'Characteristic User Description'],
    ['00002902-0000-1000-8000-00805f9b34fb', 'Client Characteristic Configuration'],
    ['00002903-0000-1000-8000-00805f9b34fb', 'Server Characteristic Configuration'],
    ['00002904-0000-1000-8000-00805f9b34fb', 'Characteristic Presentation Format'],
    ['00002905-0000-1000-8000-00805f9b34fb', 'Characteristic Aggregate Format'],
    ['00002906-0000-1000-8000-00805f9b34fb', 'Valid Range'],
    ['00002907-0000-1000-8000-00805f9b34fb', 'External Report Reference'],
    ['00002908-0000-1000-8000-00805f9b34fb', 'Report Reference'],
    ['00002909-0000-1000-8000-00805f9b34fb', 'Number of Digits'],
    ['0000290a-0000-1000-8000-00805f9b34fb', 'Trigger Setting'],
    ['0000290b-0000-1000-8000-00805f9b34fb', 'Environmental Sensing Configuration'],
    ['0000290c-0000-1000-8000-00805f9b34fb', 'Environmental Sensing Measurement'],
    ['0000290d-0000-1000-8000-00805f9b34fb', 'Environmental Sensing Trigger Setting'],

    ['0000b000-0000-1000-8000-00805f9b34fb', 'Weight Scale'],
    ['0000b001-0000-1000-8000-00805f9b34fb', 'Weight Scale Measurement'],
    ['0000c000-0000-1000-8000-00805f9b34fb', 'Continuout Gluecose Measurement'],
    ['0000c001-0000-1000-8000-00805f9b34fb', 'Continuous Glucose Measurement'],
    ['0000c002-0000-1000-8000-00805f9b34fb', 'Continuous Glucose Features'],
    ['0000c003-0000-1000-8000-00805f9b34fb', 'Continuous Glucose Status'],
    ['0000c004-0000-1000-8000-00805f9b34fb', 'Continuous Glucose Session'],
    ['0000c005-0000-1000-8000-00805f9b34fb', 'Continuous Glucose Runtime'],
    ['0000c006-0000-1000-8000-00805f9b34fb', 'Continuous Glucose RACP'],
    ['0000c007-0000-1000-8000-00805f9b34fb', 'Continuous Glucose ASCP'],
    ['0000c008-0000-1000-8000-00805f9b34fb', 'Continuous Glucose CGMCP'],
    ['0000d000-0000-1000-8000-00805f9b34fb', 'Pedometer'],
    ['0000d001-0000-1000-8000-00805f9b34fb', 'Pedometer Measurement'],
    ['0000e000-0000-1000-8000-00805f9b34fb', 'Audio Temp'],
    ['0000e001-0000-1000-8000-00805f9b34fb', 'Audio Battery Level Temp'],
    ['0000e002-0000-1000-8000-00805f9b34fb', 'Audio LeftRight Temp'],
    ['0000e003-0000-1000-8000-00805f9b34fb', 'Audio Hi ID Temp'],
    ['0000e004-0000-1000-8000-00805f9b34fb', 'Audio Other Hi ID Temp'],
    ['0000e005-0000-1000-8000-00805f9b34fb', 'Audio Mic Attenuation Temp'],
    ['0000e006-0000-1000-8000-00805f9b34fb', 'Audio 2nd Stream Attenuation Temp'],
    ['0000e007-0000-1000-8000-00805f9b34fb', 'Audio Available Programs Bitmap Temp'],
    ['0000e008-0000-1000-8000-00805f9b34fb', 'Audio Stream Programs Bitmap Temp'],
    ['0000e009-0000-1000-8000-00805f9b34fb', 'Audio Current Active Program Temp'],
    ['0000e00a-0000-1000-8000-00805f9b34fb', 'Audio Program Data Version Temp'],
    ['0000e00b-0000-1000-8000-00805f9b34fb', 'Audio Program ID Name Selector Temp'],
    ['0000e00c-0000-1000-8000-00805f9b34fb', 'Audio Program Name Temp'],
    ['0000e00d-0000-1000-8000-00805f9b34fb', 'Audio Program Catogory Temp'],

    // 16-bit UUIDs for Members, ordered by UUID ----------------------------------------------------------------------
    ['0000fe1c-0000-1000-8000-00805f9b34fb', 'Custom UUID of NetMedia, Inc.'],
    ['0000fe1d-0000-1000-8000-00805f9b34fb', 'Custom UUID of Illuminati Instrument Corporation'],
    ['0000fe1e-0000-1000-8000-00805f9b34fb', 'Custom UUID of Smart Innovations Co., Ltd'],
    ['0000fe1f-0000-1000-8000-00805f9b34fb', 'Custom UUID of Garmin International, Inc.'],
    ['0000fe20-0000-1000-8000-00805f9b34fb', 'Custom UUID of Emerson'],
    ['0000fe21-0000-1000-8000-00805f9b34fb', 'Custom UUID of Bose Corporation'],
    ['0000fe22-0000-1000-8000-00805f9b34fb', 'Custom UUID of Zoll Medical Corporation'],
    ['0000fe23-0000-1000-8000-00805f9b34fb', 'Custom UUID of Zoll Medical Corporation'],
    ['0000fe24-0000-1000-8000-00805f9b34fb', 'Custom UUID of August Home Inc'],
    ['0000fe25-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fe26-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fe27-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fe28-0000-1000-8000-00805f9b34fb', 'Custom UUID of Ayla Networks'],
    ['0000fe29-0000-1000-8000-00805f9b34fb', 'Custom UUID of Gibson Innovations'],
    ['0000fe2a-0000-1000-8000-00805f9b34fb', 'Custom UUID of DaisyWorks, Inc.'],
    ['0000fe2b-0000-1000-8000-00805f9b34fb', 'Custom UUID of ITT Industries'],
    ['0000fe2c-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fe2d-0000-1000-8000-00805f9b34fb', 'Custom UUID of SMART INNOVATION Co.,Ltd'],
    ['0000fe2e-0000-1000-8000-00805f9b34fb', 'Custom UUID of ERi,Inc.'],
    ['0000fe2f-0000-1000-8000-00805f9b34fb', 'Custom UUID of CRESCO Wireless, Inc'],
    ['0000fe30-0000-1000-8000-00805f9b34fb', 'Custom UUID of Volkswagen AG'],
    ['0000fe31-0000-1000-8000-00805f9b34fb', 'Custom UUID of Volkswagen AG'],
    ['0000fe32-0000-1000-8000-00805f9b34fb', 'Custom UUID of Pro-Mark, Inc.'],
    ['0000fe33-0000-1000-8000-00805f9b34fb', 'Custom UUID of CHIPOLO d.o.o.'],
    ['0000fe34-0000-1000-8000-00805f9b34fb', 'Custom UUID of SmallLoop LLC'],
    ['0000fe35-0000-1000-8000-00805f9b34fb', 'Custom UUID of HUAWEI Technologies Co., Ltd'],
    ['0000fe36-0000-1000-8000-00805f9b34fb', 'Custom UUID of HUAWEI Technologies Co., Ltd'],
    ['0000fe37-0000-1000-8000-00805f9b34fb', 'Custom UUID of Spaceek LTD'],
    ['0000fe38-0000-1000-8000-00805f9b34fb', 'Custom UUID of Spaceek LTD'],
    ['0000fe39-0000-1000-8000-00805f9b34fb', 'Custom UUID of TTS Tooltechnic Systems AG & Co. KG'],
    ['0000fe3a-0000-1000-8000-00805f9b34fb', 'Custom UUID of TTS Tooltechnic Systems AG & Co. KG'],
    ['0000fe3b-0000-1000-8000-00805f9b34fb', 'Custom UUID of Dolby Laboratories'],
    ['0000fe3c-0000-1000-8000-00805f9b34fb', 'Custom UUID of Alibaba'],
    ['0000fe3d-0000-1000-8000-00805f9b34fb', 'Custom UUID of BD Medical'],
    ['0000fe3e-0000-1000-8000-00805f9b34fb', 'Custom UUID of BD Medical'],
    ['0000fe3f-0000-1000-8000-00805f9b34fb', 'Custom UUID of Friday Labs Limited'],
    ['0000fe40-0000-1000-8000-00805f9b34fb', 'Custom UUID of Inugo Systems Limited'],
    ['0000fe41-0000-1000-8000-00805f9b34fb', 'Custom UUID of Inugo Systems Limited'],
    ['0000fe42-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nets A/S'],
    ['0000fe43-0000-1000-8000-00805f9b34fb', 'Custom UUID of Andreas Stihl AG & Co. KG'],
    ['0000fe44-0000-1000-8000-00805f9b34fb', 'Custom UUID of SK Telecom'],
    ['0000fe45-0000-1000-8000-00805f9b34fb', 'Custom UUID of Snapchat Inc'],
    ['0000fe46-0000-1000-8000-00805f9b34fb', 'Custom UUID of B&O Play A/S'],
    ['0000fe47-0000-1000-8000-00805f9b34fb', 'Custom UUID of General Motors'],
    ['0000fe48-0000-1000-8000-00805f9b34fb', 'Custom UUID of General Motors'],
    ['0000fe49-0000-1000-8000-00805f9b34fb', 'Custom UUID of SenionLab AB'],
    ['0000fe4a-0000-1000-8000-00805f9b34fb', 'Custom UUID of OMRON HEALTHCARE Co., Ltd.'],
    ['0000fe4b-0000-1000-8000-00805f9b34fb', 'Custom UUID of Koninklijke Philips N.V.'],
    ['0000fe4c-0000-1000-8000-00805f9b34fb', 'Custom UUID of Volkswagen AG'],
    ['0000fe4d-0000-1000-8000-00805f9b34fb', 'Custom UUID of Casambi Technologies Oy'],
    ['0000fe4e-0000-1000-8000-00805f9b34fb', 'Custom UUID of NTT docomo'],
    ['0000fe4f-0000-1000-8000-00805f9b34fb', 'Custom UUID of Molekule, Inc.'],
    ['0000fe50-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fe51-0000-1000-8000-00805f9b34fb', 'Custom UUID of SRAM'],
    ['0000fe52-0000-1000-8000-00805f9b34fb', 'Custom UUID of SetPoint Medical'],
    ['0000fe53-0000-1000-8000-00805f9b34fb', 'Custom UUID of 3M'],
    ['0000fe54-0000-1000-8000-00805f9b34fb', 'Custom UUID of Motiv, Inc.'],
    ['0000fe55-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fe56-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fe57-0000-1000-8000-00805f9b34fb', 'Custom UUID of Dotted Labs'],
    ['0000fe58-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nordic Semiconductor ASA'],
    ['0000fe59-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nordic Semiconductor ASA'],
    ['0000fe5a-0000-1000-8000-00805f9b34fb', 'Custom UUID of Chronologics Corporation'],
    ['0000fe5b-0000-1000-8000-00805f9b34fb', 'Custom UUID of GT-tronics HK Ltd'],
    ['0000fe5c-0000-1000-8000-00805f9b34fb', 'Custom UUID of million hunters GmbH'],
    ['0000fe5d-0000-1000-8000-00805f9b34fb', 'Custom UUID of Grundfos A/S'],
    ['0000fe5e-0000-1000-8000-00805f9b34fb', 'Custom UUID of Plastc Corporation'],
    ['0000fe5f-0000-1000-8000-00805f9b34fb', 'Custom UUID of Eyefi, Inc.'],
    ['0000fe60-0000-1000-8000-00805f9b34fb', 'Custom UUID of Lierda Science & Technology Group Co., Ltd.'],
    ['0000fe61-0000-1000-8000-00805f9b34fb', 'Custom UUID of Logitech International SA'],
    ['0000fe62-0000-1000-8000-00805f9b34fb', 'Custom UUID of Indagem Tech LLC'],
    ['0000fe63-0000-1000-8000-00805f9b34fb', 'Custom UUID of Connected Yard, Inc.'],
    ['0000fe64-0000-1000-8000-00805f9b34fb', 'Custom UUID of Siemens AG'],
    ['0000fe65-0000-1000-8000-00805f9b34fb', 'Custom UUID of CHIPOLO d.o.o.'],
    ['0000fe66-0000-1000-8000-00805f9b34fb', 'Custom UUID of Intel Corporation'],
    ['0000fe67-0000-1000-8000-00805f9b34fb', 'Custom UUID of Lab Sensor Solutions'],
    ['0000fe68-0000-1000-8000-00805f9b34fb', 'Custom UUID of Qualcomm Life Inc'],
    ['0000fe69-0000-1000-8000-00805f9b34fb', 'Custom UUID of Qualcomm Life Inc'],
    ['0000fe6a-0000-1000-8000-00805f9b34fb', 'Custom UUID of Kontakt Micro-Location Sp. z o.o.'],
    ['0000fe6b-0000-1000-8000-00805f9b34fb', 'Custom UUID of TASER International, Inc.'],
    ['0000fe6c-0000-1000-8000-00805f9b34fb', 'Custom UUID of TASER International, Inc.'],
    ['0000fe6d-0000-1000-8000-00805f9b34fb', 'Custom UUID of The University of Tokyo'],
    ['0000fe6e-0000-1000-8000-00805f9b34fb', 'Custom UUID of The University of Tokyo'],
    ['0000fe6f-0000-1000-8000-00805f9b34fb', 'Custom UUID of LINE Corporation'],
    ['0000fe70-0000-1000-8000-00805f9b34fb', 'Custom UUID of Beijing Jingdong Century Trading Co., Ltd.'],
    ['0000fe71-0000-1000-8000-00805f9b34fb', 'Custom UUID of Plume Design Inc'],
    ['0000fe72-0000-1000-8000-00805f9b34fb', 'Custom UUID of St. Jude Medical, Inc.'],
    ['0000fe73-0000-1000-8000-00805f9b34fb', 'Custom UUID of St. Jude Medical, Inc.'],
    ['0000fe74-0000-1000-8000-00805f9b34fb', 'Custom UUID of unwire'],
    ['0000fe75-0000-1000-8000-00805f9b34fb', 'Custom UUID of TangoMe'],
    ['0000fe76-0000-1000-8000-00805f9b34fb', 'Custom UUID of TangoMe'],
    ['0000fe77-0000-1000-8000-00805f9b34fb', 'Custom UUID of Hewlett-Packard Company'],
    ['0000fe78-0000-1000-8000-00805f9b34fb', 'Custom UUID of Hewlett-Packard Company'],
    ['0000fe79-0000-1000-8000-00805f9b34fb', 'Custom UUID of Zebra Technologies'],
    ['0000fe7a-0000-1000-8000-00805f9b34fb', 'Custom UUID of Bragi GmbH'],
    ['0000fe7b-0000-1000-8000-00805f9b34fb', 'Custom UUID of Orion Labs, Inc.'],
    ['0000fe7c-0000-1000-8000-00805f9b34fb', 'Custom UUID of Telit Wireless Solutions (Formerly Stollmann E+V GmbH)'],
    ['0000fe7d-0000-1000-8000-00805f9b34fb', 'Custom UUID of Aterica Health Inc.'],
    ['0000fe7e-0000-1000-8000-00805f9b34fb', 'Custom UUID of Awear Solutions Ltd'],
    ['0000fe7f-0000-1000-8000-00805f9b34fb', 'Custom UUID of Doppler Lab'],
    ['0000fe80-0000-1000-8000-00805f9b34fb', 'Custom UUID of Doppler Lab'],
    ['0000fe81-0000-1000-8000-00805f9b34fb', 'Custom UUID of Medtronic Inc.'],
    ['0000fe82-0000-1000-8000-00805f9b34fb', 'Custom UUID of Medtronic Inc.'],
    ['0000fe83-0000-1000-8000-00805f9b34fb', 'Custom UUID of Blue Bite'],
    ['0000fe84-0000-1000-8000-00805f9b34fb', 'Custom UUID of RF Digital Corp'],
    ['0000fe85-0000-1000-8000-00805f9b34fb', 'Custom UUID of RF Digital Corp'],
    ['0000fe86-0000-1000-8000-00805f9b34fb', 'Custom UUID of HUAWEI Technologies Co., Ltd. ( )'],
    ['0000fe87-0000-1000-8000-00805f9b34fb', 'Custom UUID of Qingdao Yeelink Information Technology Co., Ltd. ( )'],
    ['0000fe88-0000-1000-8000-00805f9b34fb', 'Custom UUID of SALTO SYSTEMS S.L.'],
    ['0000fe89-0000-1000-8000-00805f9b34fb', 'Custom UUID of B&O Play A/S'],
    ['0000fe8a-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fe8b-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fe8c-0000-1000-8000-00805f9b34fb', 'Custom UUID of TRON Forum'],
    ['0000fe8d-0000-1000-8000-00805f9b34fb', 'Custom UUID of Interaxon Inc.'],
    ['0000fe8e-0000-1000-8000-00805f9b34fb', 'Custom UUID of ARM Ltd'],
    ['0000fe8f-0000-1000-8000-00805f9b34fb', 'Custom UUID of CSR'],
    ['0000fe90-0000-1000-8000-00805f9b34fb', 'Custom UUID of JUMA'],
    ['0000fe91-0000-1000-8000-00805f9b34fb', 'Custom UUID of Shanghai Imilab Technology Co.,Ltd'],
    ['0000fe92-0000-1000-8000-00805f9b34fb', 'Custom UUID of Jarden Safety & Security'],
    ['0000fe93-0000-1000-8000-00805f9b34fb', 'Custom UUID of OttoQ Inc.'],
    ['0000fe94-0000-1000-8000-00805f9b34fb', 'Custom UUID of OttoQ Inc.'],
    ['0000fe95-0000-1000-8000-00805f9b34fb', 'Custom UUID of Xiaomi Inc.'],
    ['0000fe96-0000-1000-8000-00805f9b34fb', 'Custom UUID of Tesla Motor Inc.'],
    ['0000fe97-0000-1000-8000-00805f9b34fb', 'Custom UUID of Tesla Motor Inc.'],
    ['0000fe98-0000-1000-8000-00805f9b34fb', 'Custom UUID of Currant, Inc.'],
    ['0000fe99-0000-1000-8000-00805f9b34fb', 'Custom UUID of Currant, Inc.'],
    ['0000fe9a-0000-1000-8000-00805f9b34fb', 'Custom UUID of Estimote'],
    ['0000fe9b-0000-1000-8000-00805f9b34fb', 'Custom UUID of Samsara Networks, Inc'],
    ['0000fe9c-0000-1000-8000-00805f9b34fb', 'Custom UUID of GSI Laboratories, Inc.'],
    ['0000fe9d-0000-1000-8000-00805f9b34fb', 'Custom UUID of Mobiquity Networks Inc'],
    ['0000fe9e-0000-1000-8000-00805f9b34fb', 'Custom UUID of Dialog Semiconductor B.V.'],
    ['0000fe9f-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fea0-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fea1-0000-1000-8000-00805f9b34fb', 'Custom UUID of Intrepid Control Systems, Inc.'],
    ['0000fea2-0000-1000-8000-00805f9b34fb', 'Custom UUID of Intrepid Control Systems, Inc.'],
    ['0000fea3-0000-1000-8000-00805f9b34fb', 'Custom UUID of ITT Industries'],
    ['0000fea4-0000-1000-8000-00805f9b34fb', 'Custom UUID of Paxton Access Ltd'],
    ['0000fea5-0000-1000-8000-00805f9b34fb', 'Custom UUID of GoPro, Inc.'],
    ['0000fea6-0000-1000-8000-00805f9b34fb', 'Custom UUID of GoPro, Inc.'],
    ['0000fea7-0000-1000-8000-00805f9b34fb', 'Custom UUID of UTC Fire and Security'],
    ['0000fea8-0000-1000-8000-00805f9b34fb', 'Custom UUID of Savant Systems LLC'],
    ['0000fea9-0000-1000-8000-00805f9b34fb', 'Custom UUID of Savant Systems LLC'],
    ['0000feaa-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000feab-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nokia Corporation'],
    ['0000feac-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nokia Corporation'],
    ['0000fead-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nokia Corporation'],
    ['0000feae-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nokia Corporation'],
    ['0000feaf-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nest Labs Inc.'],
    ['0000feb0-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nest Labs Inc.'],
    ['0000feb1-0000-1000-8000-00805f9b34fb', 'Custom UUID of Electronics Tomorrow Limited'],
    ['0000feb2-0000-1000-8000-00805f9b34fb', 'Custom UUID of Microsoft Corporation'],
    ['0000feb3-0000-1000-8000-00805f9b34fb', 'Custom UUID of Taobao'],
    ['0000feb4-0000-1000-8000-00805f9b34fb', 'Custom UUID of WiSilica Inc.'],
    ['0000feb5-0000-1000-8000-00805f9b34fb', 'Custom UUID of WiSilica Inc.'],
    ['0000feb6-0000-1000-8000-00805f9b34fb', 'Custom UUID of Vencer Co, Ltd'],
    ['0000feb7-0000-1000-8000-00805f9b34fb', 'Custom UUID of Facebook, Inc.'],
    ['0000feb8-0000-1000-8000-00805f9b34fb', 'Custom UUID of Facebook, Inc.'],
    ['0000feb9-0000-1000-8000-00805f9b34fb', 'Custom UUID of LG Electronics'],
    ['0000feba-0000-1000-8000-00805f9b34fb', 'Custom UUID of Tencent Holdings Limited'],
    ['0000febb-0000-1000-8000-00805f9b34fb', 'Custom UUID of adafruit industries'],
    ['0000febc-0000-1000-8000-00805f9b34fb', 'Custom UUID of Dexcom, Inc.'],
    ['0000febd-0000-1000-8000-00805f9b34fb', 'Custom UUID of Clover Network, Inc.'],
    ['0000febe-0000-1000-8000-00805f9b34fb', 'Custom UUID of Bose Corporation'],
    ['0000febf-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nod, Inc.'],
    ['0000fec0-0000-1000-8000-00805f9b34fb', 'Custom UUID of KDDI Corporation'],
    ['0000fec1-0000-1000-8000-00805f9b34fb', 'Custom UUID of KDDI Corporation'],
    ['0000fec2-0000-1000-8000-00805f9b34fb', 'Custom UUID of Blue Spark Technologies, Inc.'],
    ['0000fec3-0000-1000-8000-00805f9b34fb', 'Custom UUID of 360fly, Inc.'],
    ['0000fec4-0000-1000-8000-00805f9b34fb', 'Custom UUID of PLUS Location Systems'],
    ['0000fec5-0000-1000-8000-00805f9b34fb', 'Custom UUID of Realtek Semiconductor Corp.'],
    ['0000fec6-0000-1000-8000-00805f9b34fb', 'Custom UUID of Kocomojo, LLC'],
    ['0000fec7-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fec8-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fec9-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000feca-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fecb-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fecc-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fecd-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fece-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fecf-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fed0-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fed1-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fed2-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fed3-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fed4-0000-1000-8000-00805f9b34fb', 'Custom UUID of Apple, Inc.'],
    ['0000fed5-0000-1000-8000-00805f9b34fb', 'Custom UUID of Plantronics Inc.'],
    ['0000fed6-0000-1000-8000-00805f9b34fb', 'Custom UUID of Broadcom Corporation'],
    ['0000fed7-0000-1000-8000-00805f9b34fb', 'Custom UUID of Broadcom Corporation'],
    ['0000fed8-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fed9-0000-1000-8000-00805f9b34fb', 'Custom UUID of Pebble Technology Corporation'],
    ['0000feda-0000-1000-8000-00805f9b34fb', 'Custom UUID of ISSC Technologies Corporation'],
    ['0000fedb-0000-1000-8000-00805f9b34fb', 'Custom UUID of Perka, Inc.'],
    ['0000fedc-0000-1000-8000-00805f9b34fb', 'Custom UUID of Jawbone'],
    ['0000fedd-0000-1000-8000-00805f9b34fb', 'Custom UUID of Jawbone'],
    ['0000fede-0000-1000-8000-00805f9b34fb', 'Custom UUID of Coin, Inc.'],
    ['0000fedf-0000-1000-8000-00805f9b34fb', 'Custom UUID of Design SHIFT'],
    ['0000fee0-0000-1000-8000-00805f9b34fb', 'Custom UUID of Anhui Huami Information Technology Co.'],
    ['0000fee1-0000-1000-8000-00805f9b34fb', 'Custom UUID of Anhui Huami Information Technology Co.'],
    ['0000fee2-0000-1000-8000-00805f9b34fb', 'Custom UUID of Anki, Inc.'],
    ['0000fee3-0000-1000-8000-00805f9b34fb', 'Custom UUID of Anki, Inc.'],
    ['0000fee4-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nordic Semiconductor ASA'],
    ['0000fee5-0000-1000-8000-00805f9b34fb', 'Custom UUID of Nordic Semiconductor ASA'],
    ['0000fee6-0000-1000-8000-00805f9b34fb', 'Custom UUID of Silvair, Inc.'],
    ['0000fee7-0000-1000-8000-00805f9b34fb', 'Custom UUID of Tencent Holdings Limited'],
    ['0000fee8-0000-1000-8000-00805f9b34fb', 'Custom UUID of Quintic Corp.'],
    ['0000fee9-0000-1000-8000-00805f9b34fb', 'Custom UUID of Quintic Corp.'],
    ['0000feea-0000-1000-8000-00805f9b34fb', 'Custom UUID of Swirl Networks, Inc.'],
    ['0000feeb-0000-1000-8000-00805f9b34fb', 'Custom UUID of Swirl Networks, Inc.'],
    ['0000feec-0000-1000-8000-00805f9b34fb', 'Custom UUID of Tile, Inc.'],
    ['0000feed-0000-1000-8000-00805f9b34fb', 'Custom UUID of Tile, Inc.'],
    ['0000feee-0000-1000-8000-00805f9b34fb', 'Custom UUID of Polar Electro Oy'],
    ['0000feef-0000-1000-8000-00805f9b34fb', 'Custom UUID of Polar Electro Oy'],
    ['0000fef0-0000-1000-8000-00805f9b34fb', 'Custom UUID of Intel'],
    ['0000fef1-0000-1000-8000-00805f9b34fb', 'Custom UUID of CSR'],
    ['0000fef2-0000-1000-8000-00805f9b34fb', 'Custom UUID of CSR'],
    ['0000fef3-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fef4-0000-1000-8000-00805f9b34fb', 'Custom UUID of Google Inc.'],
    ['0000fef5-0000-1000-8000-00805f9b34fb', 'Custom UUID of Dialog Semiconductor GmbH'],
    ['0000fef6-0000-1000-8000-00805f9b34fb', 'Custom UUID of Wicentric, Inc.'],
    ['0000fef7-0000-1000-8000-00805f9b34fb', 'Custom UUID of Aplix Corporation'],
    ['0000fef8-0000-1000-8000-00805f9b34fb', 'Custom UUID of Aplix Corporation'],
    ['0000fef9-0000-1000-8000-00805f9b34fb', 'Custom UUID of PayPal, Inc.'],
    ['0000fefa-0000-1000-8000-00805f9b34fb', 'Custom UUID of PayPal, Inc.'],
    ['0000fefb-0000-1000-8000-00805f9b34fb', 'Custom UUID of Telit Wireless Solutions (Formerly Stollmann E+V GmbH)'],
    ['0000fefc-0000-1000-8000-00805f9b34fb', 'Custom UUID of Gimbal, Inc.'],
    ['0000fefd-0000-1000-8000-00805f9b34fb', 'Custom UUID of Gimbal, Inc.'],
    ['0000fefe-0000-1000-8000-00805f9b34fb', 'Custom UUID of GN ReSound A/S'],
    ['0000feff-0000-1000-8000-00805f9b34fb', 'Custom UUID of GN Netcom'],


    // Non-Standard public, ordered by UUID -------------------------------------------------------------------------------------------------------

    ['0000ffe0-0000-1000-8000-00805f9b34fb', 'HM-10 UART Service'],
    ['0000ffe1-0000-1000-8000-00805f9b34fb', 'HM-10 UART RX/TX'],

    //Posture Sensing Service
    ['0000ffe0-0000-1000-8000-00805f9b34fb', 'Posture Sensing Service'], //collision with HM-10
    ['0000ffe5-0000-1000-8000-00805f9b34fb', 'Control Service'],
    ['0000ffe9-0000-1000-8000-00805f9b34fb', 'UART TX'],

    ['0000fff0-0000-1000-8000-00805f9b34fb', 'ISSC Transparent Service'],
    ['0000fff1-0000-1000-8000-00805f9b34fb', 'ISSC Transparent RX'],
    ['0000fff2-0000-1000-8000-00805f9b34fb', 'ISSC Transparent TX'],


    ['0000fff6-0000-1000-8000-00805f9b34fb', 'RX'],
    ['0000fff7-0000-1000-8000-00805f9b34fb', 'TX'],

    //16 Bit UUIDs For SDOs, ordered by UUID ----------------------------------------------------------------------
    ['0000fffc-0000-1000-8000-00805f9b34fb', 'AirFuel Alliance Wireless Power Transfer Service'],
    ['0000fffd-0000-1000-8000-00805f9b34fb', 'Fast IDentity Online Alliance Universal Second Factor Authenticator Service'],
    ['0000fffe-0000-1000-8000-00805f9b34fb', 'AirFuel Alliance Wireless Power Transfer Service'],

    //Nodric Service
    ['6e400001-b5a3-f393-e0a9-e50e24dcca9e', 'Nodric UART Service'],
    ['6e400002-b5a3-f393-e0a9-e50e24dcca9e', 'Nodric UART Rx Char'],
    ['6e400003-b5a3-f393-e0a9-e50e24dcca9e', 'Nodric UART Tx Char'],
    ['00001530-1212-efde-1523-785feabcd123', 'Nodric DFU Service'],
    ['00001531-1212-efde-1523-785feabcd123', 'Nodric DFU Control Point'],
    ['00001532-1212-efde-1523-785feabcd123', 'Nodric DFU Packet'],

    //Read Bear
    ['713d0000-503e-4c75-ba94-3148f18d941e', 'RedBearLab Service'],
    ['713d0002-503e-4c75-ba94-3148f18d941e', 'RedBearLab RX Service'],
    ['713d0003-503e-4c75-ba94-3148f18d941e', 'RedBearLab TX Service'],

    // RedBear Beacon B1
    ['b0702881-a295-a8ab-f734-031a98a512d', 'RedBear B1 iBeacon'],
    ['b0702882-a295-a8ab-f734-031a98a512d', 'RedBear B1 Major ID'],
    ['b0702883-a295-a8ab-f734-031a98a512d', 'RedBear B1 Minor ID'],
    ['b0702884-a295-a8ab-f734-031a98a512d', 'RedBear B1 Measured Power'],
    ['b0702885-a295-a8ab-f734-031a98a512d', 'RedBear B1 LED Switch '],
    ['b0702886-a295-a8ab-f734-031a98a512d', 'RedBear B1 Advertising Interval'],
    ['b0702887-a295-a8ab-f734-031a98a512d', 'RedBear B1 Output Power'],
    ['b0702888-a295-a8ab-f734-031a98a512d', 'RedBear B1 Firmware Version'],

    //ISSC dual mode
    ['49535343-fe7d-4ae5-8fa9-9fafd205e455', 'ISSC Proprietary Service'],
    ['49535343-1e4d-4bd9-ba61-23c647249616', 'ISSC Transparent TX'],
    ['49535343-8841-43f4-a8d4-ecbe34729bb3', 'ISSC Transparent RX'],
    ['49535343-6daa-4d02-abf6-19569aca69fe', 'ISSC Update Connection Parameter'],
    ['49535343-6daa-4d02-abf6-19569aca69fe', 'ISSC Update Connection Parameter'],
    ['49535343-aca3-481c-91ec-d85e28a60318', 'ISSC Air Patch'],

    //Stollmann Terminal IO
    ['0000fefb-0000-1000-8000-00805f9b34fb', 'Stollmann Terminal IO Service'],
    ['00000001-0000-1000-8000-008025000000', 'Stollmann UART RX'],
    ['00000002-0000-1000-8000-008025000000', 'Stollmann UART TX'],
    ['00000004-0000-1000-8000-008025000000', 'Stollmann Credits UART RX'],

    //Apple Notification Center Service
    ['7905f431-b5ce-4e99-a40f-4b1e122d00d0', 'Apple Notification Center Service'],
    ['9fbf120d-6301-42d9-8c58-25e699a21dbd', 'Notification Source'],
    ['69d1d8f3-45e1-49a8-9821-9bbdfdaad9d9', 'Control Point UUID'],
    ['22eac6e9-24d6-4bb5-be44-b36ace7c7bfb', 'Data Source'],

    //Laird BL600 Virtual Serial Port Service
    ['569a1101-b87f-490c-92cb-11ba5ea5167c', 'BL600 vSP Service'],
    ['569a2000-b87f-490c-92cb-11ba5ea5167c', 'TX FIFO'],
    ['569a2001-b87f-490c-92cb-11ba5ea5167c', 'RX FIFO'],
    ['569a2002-b87f-490c-92cb-11ba5ea5167c', 'Modem Out'],
    ['569a2003-b87f-490c-92cb-11ba5ea5167c', 'Modem In'],

    // Other...
    ['8ec90003-f315-4f60-9fb8-838830daea50', 'Nordic Buttonless DFU']
])


export const ARANET_CO2_MEASUREMENT_CHARACTERISTIC_UUID = "f0cd1503-95da-4f4b-9ac8-aa55d312af0c";
export const ARANET_TOTAL_MEASUREMENTS_UUID = "f0cd2001-95da-4f4b-9ac8-aa55d312af0c";
export const ARANET_MEASUREMENT_INTERVAL_UUID = "f0cd2002-95da-4f4b-9ac8-aa55d312af0c";
export const ARANET_SECONDS_LAST_UPDATE_UUID = "f0cd2004-95da-4f4b-9ac8-aa55d312af0c";
export const ARANET_CO2_MEASUREMENT_WITH_INTERVAL_TIME_CHARACTERISTIC_UUID = "f0cd3001-95da-4f4b-9ac8-aa55d312af0c";
// const ARANET_DEVICE_NAME_UUID = GENERIC_GATT_DEVICE_NAME_UUID;
// const ARANET_UNKNOWN_FIELD_1_UUID = 'f0cd1401-95da-4f4b-9ac8-aa55d312af0c';
// const ARANET_UNKNOWN_FIELD_2_UUID = 'f0cd1502-95da-4f4b-9ac8-aa55d312af0c';
export const ARANET_SET_INTERVAL_UUID = 'f0cd1402-95da-4f4b-9ac8-aa55d312af0c';
export const ARANET_SET_HISTORY_PARAMETER_UUID = 'f0cd1402-95da-4f4b-9ac8-aa55d312af0c';
 
export const ARANET_SENSOR_SETTINGS_STATE_UUID = 'f0cd1401-95da-4f4b-9ac8-aa55d312af0c';
export const ARANET_SENSOR_CALIBRATION_DATA_UUID = 'f0cd1502-95da-4f4b-9ac8-aa55d312af0c';
export const ARANET_UNSED_GATT_UUID = 'f0cd2003-95da-4f4b-9ac8-aa55d312af0c';
export const ARANET_SENSOR_LOGS_UUID = 'f0cd2005-95da-4f4b-9ac8-aa55d312af0c';

export const aranet4KnownCharacteristicUUIDDescriptions = new Map([
    [ARANET_CO2_MEASUREMENT_CHARACTERISTIC_UUID, "Aranet4: current CO2 measurement"],
    [ARANET_TOTAL_MEASUREMENTS_UUID, "Aranet4: total number of measurements"],
    [ARANET_MEASUREMENT_INTERVAL_UUID, "Aranet4: measurement interval"],
    [ARANET_SECONDS_LAST_UPDATE_UUID, "Aranet4: seconds since last update"],
    [ARANET_CO2_MEASUREMENT_WITH_INTERVAL_TIME_CHARACTERISTIC_UUID, "Aranet4: CO2 measurements, interval, time since measurements"],
    [GENERIC_GATT_DEVICE_NAME_UUID, "Device Name"],
    [GENERIC_GATT_DEVICE_BATTERY_LEVEL_UUID, "Aranet4: Battery level"],
    [GENERIC_GATT_DEVICE_MODEL_NUMBER_STRING_UUID, "Model Number String"],
    [GENERIC_GATT_SERIAL_NUMBER_STRING_UUID, "Serial Number String"],
    [GENERIC_GATT_HARDWARE_REVISION_STRING_UUID, "Hardware Revision String"],
    [GENERIC_GATT_SOFTWARE_REVISION_STRING_UUID, "Software Revision String"],
    [GENERIC_GATT_MANUFACTURER_NAME_STRING_UUID, "Manufacturer Name String"],
    [ARANET_SET_INTERVAL_UUID, "Set measurement interval"],
    [ARANET_SET_HISTORY_PARAMETER_UUID, "Set \"History Parameter\""],
    [ARANET_SENSOR_SETTINGS_STATE_UUID, "Aranet4 sensor settings state"],
    [ARANET_SENSOR_CALIBRATION_DATA_UUID, "Aranet4 sensor calibration"],
    [ARANET_UNSED_GATT_UUID, "Aranet4 UNUSED GATT characteristic"],
    [ARANET_SENSOR_LOGS_UUID, "Aranet4 sensor logs"]
]);

//0x10000000000000000 === 18446744073709551615 === 2 ^60
export const ARANET4_MINIMUM_FACTORY_CALIBRATION_VALUE = BigInt(0x10000000000000000);
export const UNIX_MONDAY_JANUARY_1_2018 = BigInt(1514764800);
export const ARANET_4_BAD_CALIBRATION_STRING = "Calibration unstable, bad, or otherwise not at factory in unspecified ways.";

export const ARANET4_1503_CO2_SENSOR_CHARACTERISTIC_OFFSETS = new Map([
    ['CO2', 0],
    ['TEMPERATURE', 2],
    ['PRESSURE', 4],
    ['HUMIDITY', 6],
    ['BATTERY', 7],
    ['STATUS_COLOR', 8]
]);